@use "../base/variables";

#contributing-page {
  .tabbed-navigation {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    background: white;

    li {
      float: left;
      margin: 0 1em 0 0;

      a {
        display: block;
        text-decoration: none;
        padding: 0.5em 0.5em;
        font-weight: bold;
        border-bottom: 0.5em solid variables.$gray-border;

        &.active {
          border-color: #000;
        }
      }
    }
  }

  ul.issues-list {
    li {
      .issue-label {
        border-radius: 2em;
        padding: 0 7px;
        margin-left: 5px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        white-space: nowrap;
        border: 1px solid transparent;
      }
      .tag-wontfix {
        background-color: #e4e669;
        color: #ffffff;
        border-color: #000;
      }
      .tag-question {
        background-color: #cc317c;
        color: #fff;
      }
      .tag-invalid {
        background-color: #e4e669;
        color: #000;
      }
      .tag-help-wanted {
        background-color: #008672;
        color: #fff;
      }
      .tag-hacktoberfest {
        background-color: #f2b36f;
        color: #000;
      }
      .tag-good-first-issue {
        background-color: #7057ff;
        color: #fff;
      }
      .tag-enhancement {
        background-color: #a2eeef;
        color: #000;
      }
      .tag-duplicate {
        background-color: #cfd3d7;
        color: #000;
      }
      .tag-documentation {
        background-color: #0075ca;
        color: #fff;
      }
      .tag-bug {
        background-color: #d73a4a;
        color: #fff;
      }
    }
  }
}
