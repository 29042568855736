$purple: #652f8f;
$pink: #e90e8b;
$gray: #999999;
$dark-gray: #333333;
$gray-border: #cecece;

// derived from bootstrap variables

// Extra small screen / phone
$screen-xs: 480px !default;
$screen-phone: $screen-xs !default;

// Small screen / tablet
$screen-sm: 768px !default;
$screen-tablet: $screen-sm !default;

// Medium screen / desktop
$screen-md: 1024px !default;
$screen-desktop: $screen-md !default;

// Large screen / wide desktop
$screen-lg: 1366px !default;
$screen-lg-desktop: $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1) !default;
$screen-sm-max: ($screen-md - 1) !default;
$screen-md-max: ($screen-lg - 1) !default;
