@use "../base/mixins";
@use "../base/variables";

#home-page {
  grid-row-grap: 1em;
  @include mixins.readable-content;

  .two-column-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;

    .text {
      aspect-ratio: 1/1;
      background-color: #fff;
      border-radius: 25px;
      padding: 40px;

      h1 {
        font-size: 3.5em;
        font-weight: 500;
        margin: 0 0 0.5em 0;
      }

      h2 {
        margin-top: 0;
      }

      .tagline {
        font-size: 1.5em;
        font-weight: 400;
        margin-bottom: 1em;
      }

      .get-started {
        a {
          @include mixins.rounded-button;
        }
      }
    }

    .image {
      position: relative;

      img {
        aspect-ratio: 1/1;
        object-fit: cover;
        width: 100%;
        height: auto;
        border-radius: 25px;
      }

      .image-title {
        position: absolute;
        bottom: 25px;
        left: 20px;
        right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        span {
          align-self: flex-end;
          padding-right: 20px;

          p {
            vertical-align: bottom;
            color: #fff;
            padding: 10px;
            margin: 0;
            font-size: 24px;
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.7);

            a {
              color: #fff;
              text-decoration: none;

              &:visited {
                color: #fff;
              }
            }
          }
        }

        img {
          height: 90px;
          width: 90px;
          aspect-ratio: auto;
          object-fit: none;
          background-color: rgba(0, 0, 0, 0.7);
          border-radius: 10px;
        }
      }
    }
  }

  .one-column-content {
    background-color: #fff;
    border-radius: 25px;
    margin-bottom: 20px;
    padding: 50px;
    font-size: 18px;

    h2,
    h3 {
      padding-top: 0;
      margin-top: 0;
    }

    section {
      display: grid;
      grid-template-columns: 1fr 2fr;
      column-gap: 70px;

      .icon-block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 1fr;
        column-gap: 70px;
      }

      .icon-block > div {
        display: grid;
        grid-template-columns: 40px 1fr;
        row-gap: 40px;
        column-gap: 20px;
      }
    }

    .get-started {
      a {
        @include mixins.rounded-button;
      }
    }
  }
}
@media only screen and (max-width: variables.$screen-md-max) {
  #home-page {
    .two-column-content {
      .text {
        padding: 20px;

        h1 {
          font-size: 2.5em;
        }

        h2 {
          font-size: 1.5em;
          margin-top: 0;
        }

        .tagline {
          font-size: 1.2em;
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$screen-md) {
  .hidden-tablet {
    display: none;
  }

  #home-page {
    .two-column-content {
      .text {
        padding: 20px;

        h1 {
          font-size: 1.5em;
          margin: 0;
          padding: 0;
        }

        h2 {
          font-size: 1.2em;
        }

        .tagline {
          font-size: 1em;
          margin: 0;
          padding: 0;
        }
      }
      .image .image-title img {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: variables.$screen-xs-max) {
  #home-page {
    .two-column-content {
      grid-template-columns: 1fr;

      .text {
        aspect-ratio: auto;
      }
    }

    .one-column-content {
      section {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 20px;

        .icon-block {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
}
