@font-face {
    font-family: 'Proxima Nova';
    src: url('https://cdn-shop.adafruit.com/fonts/proximanova/proximanova-light-webfont.woff2') format('woff2'),
         url('https://cdn-shop.adafruit.com/fonts/proximanova/proximanova-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    letter-spacing: 0.3em;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://cdn-shop.adafruit.com/fonts/proximanova/proximanova-regular-webfont.woff2') format('woff2'),
         url('https://cdn-shop.adafruit.com/fonts/proximanova/proximanova-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('https://cdn-shop.adafruit.com/fonts/proximanova/proximanova-semibold-webfont.woff2') format('woff2'),
         url('https://cdn-shop.adafruit.com/fonts/proximanova/proximanova-semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
