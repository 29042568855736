@use "sass:color";
@use "variables";

@mixin readable-content {
  display: grid;
  grid-template-columns:
    [full-start] minmax(15px, 1fr)
    [main-start] minmax(0, 1300px) [main-end]
    minmax(15px, 1fr) [full-end];

  & > * {
    grid-column: main;
  }
}

@mixin rounded-button {
  display: inline-block;
  font-size: 18px;
  padding: 15px 40px;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  background-color: variables.$purple;

  &:hover {
    color: #fff;
    background-color: color.adjust(
      variables.$purple,
      $lightness: -10%,
      $space: hsl
    );
  }
}

@mixin img-overlay {
  .img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
}
