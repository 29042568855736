@use "sass:color";
@use "../base/mixins";
@use "../base/variables";

#site-header {
  display: grid;
  grid-template-rows: 40px 1fr;
  grid-column: full;

  .wrapper {
    @include mixins.readable-content;
  }

  .content {
    display: grid;
    background-color: #fff;
    height: 100px;
    border-radius: 25px;
    padding: 0 25px;
    margin: 20px 0;
  }
}

.top-navigation {
  background-color: variables.$dark-gray;
  @include mixins.readable-content;

  .navigation {
    margin-left: 15px;
    padding: 10px;

    a {
      font-size: 16px;
      color: #aaa;
      margin-right: 40px;

      &.active {
        color: #fff;
      }

      &:hover {
        color: #fff;
      }
    }

    a.skip-link {
      margin-left: 0;
      position: absolute;
      left: 10px;
      top: 10px;
      transform: translateY(-200%);
      transition: transform 0.6s;
      &:focus {
        transform: translateY(0%);
      }
    }
  }
}

.site-brand {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .get-started {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    a {
      @include mixins.rounded-button;
    }
  }
}

.site-logo {
  flex-basis: 1;
  display: flex;
  justify-content: center;
}

.site-banner {
  font-size: 20px;
  padding-left: 120px;

  a {
    color: #fff;
  }
}

.site-navigation {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  font-size: 20px;
  font-weight: 500;

  a {
    margin-right: 30px;
    color: variables.$purple;
    white-space: nowrap;

    &.active {
      color: variables.$dark-gray;
    }

    &:hover {
      color: variables.$dark-gray;
    }
  }
}

@media (max-width: variables.$screen-md-max) {
  .site-navigation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

@media (max-width: variables.$screen-xs-max) {
  #site-header {
    display: none !important;
  }
}
