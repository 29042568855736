@use "../base/variables";

#download-page {
  padding: 0 0 200px 0;
  display: grid;
  grid-template-columns:
    [full-start] minmax(1em, 1fr)
    [main-start] minmax(0, 80em) [main-end]
    minmax(1em, 1fr) [full-end];
  grid-row-gap: 0.5em;

  & > * {
    grid-column: main;
  }

  h1,
  h3 {
    margin: 0;
    font-weight: 500;
  }

  h3 {
    font-size: 28px;
    font-weight: 500;
    margin: 0 0 10px 0;
  }

  .download-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    grid-auto-rows: minmax(min-content, max-content);
    font-size: 18px;

    .image-section {
      position: relative;
      margin-bottom: 20px;

      img {
        display: inline-block;
        width: 100%;
        border-radius: 25px;
      }

      h1 {
        position: absolute;
        bottom: 20px;
        left: 15px;
        right: 15px;
        font-size: 22px;
        font-weight: 400;
        color: #fff;
        padding: 10px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
      }
    }

    .section {
      background-color: #fff;
      border-radius: 25px;
      padding: 20px;
      margin-bottom: 20px;
    }

    .download {
      a.download-button,
      a.download-button-unrecommended,
      button[is="cp-install-button"] {
        display: inline-block;
        width: auto;
        padding: 15px 30px 15px 30px;
        color: #fff;
        border-radius: 10px;
        border: none;
        cursor: pointer;

        i {
          padding-left: 10px;
        }
      }

      .install-instructions {
        display: block;
        width: 100%;
        text-align: center;
        margin: 1.5em 0;
      }

      .download-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      .language-select {
        float: left;
        margin-right: 1em;
        border-radius: 10px;
        border: 2px solid variables.$gray;
        overflow: hidden;
        height: 45px;
        position: relative;
        display: block;
      }

      select {
        height: 45px;
        width: 100%;
        padding: 5px;
        border: 0;
        font-size: 16px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
      .language-select:after {
        content: "\f0dc";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        padding: 15px 8px;
        position: absolute;
        right: 0;
        top: 0;
        background: #fff;
        z-index: 1;
        text-align: center;
        width: 15%;
        height: 100%;
        pointer-events: none;
        box-sizing: border-box;
      }

      details {
        summary {
          color: #fff;
          margin-top: 1em;
          padding: 0.7em;
          border-radius: 10px;
          background-color: variables.$gray;
          cursor: pointer;
        }

        .download-modules {
          // three column grid
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          font-size: 12px;
          padding-top: 1em;
        }
      }

      .stable {
        .language-select {
          border-color: variables.$purple;
        }
        .language-select:after {
          color: variables.$purple;
        }
        a.download-button,
        a.download-button-unrecommended {
          background-color: variables.$purple;
        }
      }

      .unstable {
        .language-select {
          border-color: variables.$purple;
        }
        .language-select:after {
          color: variables.$purple;
        }

        a.download-button,
        a.download-button-unrecommended {
          background-color: variables.$purple;
        }
        a.download-button:hover,
        a.download-button-unrecommended:hover {
          background-color: variables.$purple;
        }
      }

      .unrecommended {
        .language-select:after {
          color: variables.$purple;
        }

        a.download-button,
        a.download-button-unrecommended {
          background-color: variables.$purple;
        }
      }

      button[is="cp-install-button"] {
        color: variables.$purple;
        background-color: transparent;
        border: 2px solid variables.$purple;
      }

      .download-buttons {
        a,
        button {
          width: 100%;
          height: 49px;
          padding: 0;
          text-align: center;
          line-height: 49px;
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .previous-versions-buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a,
        button {
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media (max-width: variables.$screen-lg) {
  #download-page .download-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: variables.$screen-md) {
  #download-page {
    .download-section {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .download {
        .download-details,
        .release-details {
          grid-template-columns: repeat(1, 1fr);
        }

        .language-select {
          margin-right: 0;
          margin-bottom: 1em;
        }
      }
    }
  }
}

@media (max-width: variables.$screen-xs-max) {
  #download-page .download-section {
    grid-template-columns: 1fr;
  }
}
