@use "../base/mixins";

.common-layout {
  padding-bottom: 20px;
  @include mixins.readable-content;

  .content {
    background-color: #fff;
    border-radius: 25px;
    padding: 20px;
  }
}
