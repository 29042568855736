@use "sass:color";
@use "../base/mixins";
@use "../base/variables";

#downloads-page {
  padding-bottom: 200px;
  display: grid;
  grid-template-columns:
    [full-start] minmax(1em, 1fr)
    [main-start] minmax(0, 80em) [main-end]
    minmax(1em, 1fr) [full-end];
  grid-row-gap: 1em;

  & > * {
    grid-column: main;
  }

  h1 {
    margin-bottom: 0;
  }

  & > p {
    font-size: 1.2em;
    margin-top: 0;
  }

  .downloads-inputs {
    display: flex;
    flex-direction: row;
    width: 100%;

    .downloads-search {
      width: 40%;
      padding-right: 10px;

      .search-wrapper {
        position: relative;

        &:before {
          font-family: "Font Awesome\ 5 Free";
          font-size: 24px;
          font-weight: 900;
          color: #652f8f;
          content: "\f002";
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      input {
        width: 100%;
        height: 50px;
        padding: 15px;
        border-radius: 10px;
        border: 3px solid variables.$purple;
        box-sizing: border-box;
      }
    }

    .downloads-filter {
      .filter {
        display: inline-block;
        padding: 12px;
        font-size: 24px;
        color: #fff;
        background-color: variables.$purple;
        float: left;
        border-radius: 10px;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: color.adjust(
            variables.$purple,
            $lightness: -10%,
            $space: hsl
          );
        }
      }
    }

    .downloads-filter-tags {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      span {
        padding: 5px 7px 5px 7px;
        margin-left: 5px;
        background-color: variables.$purple;
        color: #fff;
        border-radius: 5px;
        font-size: 14px;
        text-transform: capitalize;

        i {
          margin-right: 5px;
          cursor: pointer;
        }
      }
    }
  }

  .downloads-filter-content {
    position: relative;
    display: none;
    // this row spans to the edge
    grid-column: span 3;
    // we need a new grid to match the outer grid for the content in this
    // container
    grid-template-columns:
      [full-start] minmax(1em, 1fr)
      [main-start] minmax(0, 80em) [main-end]
      minmax(1em, 1fr) [full-end];
    grid-column-gap: 0.5em;
    background-color: #fff;
    border-top: 1px solid variables.$gray-border;
    border-bottom: 1px solid variables.$gray-border;
    padding: 20px;

    & > div {
      grid-column: main;
      display: grid;
      grid-template-columns: repeat(3, minmax(200px, 1fr));
    }

    h2 {
      margin: 0 0 10px 0;
    }

    ul {
      list-style-type: none;
      text-decoration: none;
      margin: 0;
      padding: 0;

      li {
        padding: 4px;
        text-transform: capitalize;

        input {
          margin-right: 5px;
        }
      }
    }

    .filter-buttons {
      position: absolute;
      top: 20px;
      height: 50px;
      right: 0;
      width: 50px;

      button {
        width: 50px;
        height: 50px;
        background-color: variables.$purple;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: color.adjust(
            variables.$purple,
            $lightness: -10%,
            $space: hsl
          );
        }
      }
    }
  }

  .downloads-section {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-column-gap: 0.5em;
    grid-row-gap: 1.5em;

    .download {
      height: 100%;
      border-radius: 10px;

      .img-responsive-4by3 {
        height: 0;
        padding-bottom: 100%;
        position: relative;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        background-color: #fff;
        color: #000;
        border-radius: 25px;
        overflow: hidden;

        .details {
          padding: 10px 20px 10px 20px;
        }

        .features {
          margin: 20px 0 8px 3px;
          .feature {
            float: left;
            font-size: 13px;
            padding: 3px 5px 3px 5px;
            margin: 5px 5px 0px 0px;
            color: #fff;
            background-color: variables.$purple;
            border-radius: 5px;
            text-transform: capitalize;
          }
        }
      }

      h3 {
        font-size: 24px;
        font-weight: 500;
        margin: 5px 0 5px 0;
      }
    }
  }
  .manufacturers ul {
    max-height: 300px;
    overflow-y: scroll;
  }
}

@media (max-width: variables.$screen-md) {
  #downloads-page {
    .downloads-section {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
  }
}

@media (max-width: variables.$screen-xs-max) {
  #downloads-page {
    .downloads-inputs {
      .downloads-search {
        width: 100%;
      }
    }
    .downloads-filter-content > div {
      grid-template-columns: repeat(1, 1fr);

      & > div {
        margin-top: 10px;
      }
    }
    .downloads-section {
      grid-template-columns: repeat(1, 100%);
    }
  }
}
